@import "./_normalize.scss";

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  @apply font-sans bg-body text-white;
}

.MuiSelect-select,
.MuiOutlinedInput-root {
  @apply bg-input;

  .MuiOutlinedInput-notchedOutline {
    @apply border-corner;
  }
}

.MuiMenu-paper {
  @apply mt-2;

  .MuiList-root {
    @apply bg-input;

    .MuiMenuItem-root {
      @apply h-48px;
    }

    .network-icon {
      @apply hidden;
    }
  }
}

.MuiDialog-root {
  .MuiBackdrop-root {
    @apply bg-black/90;
  }

  .MuiDialog-container {
    .MuiPaper-root {
      background-image: unset;
      @apply bg-card w-full;
    }
  }
}

.MuiDialogTitle-root {
  @apply py-4 px-6;
}
