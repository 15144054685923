@font-face {
  font-family: "Frontage";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/Frontage-Regular.otf");
}

@layer base {
  .font-title {
    font-family: "Frontage", sans-serif;
  }
}
